.rw-widget {
	box-shadow: 0 1px 2px 0 rgba(0,0,0,0.3);
	margin-bottom: 0.4em;
}

.rw-widget.rw-open {
	box-shadow: 2px 0 2px -2px rgba(0,0,0,0.3), -2px 0 2px -2px rgba(0,0,0,0.3);
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.rw-widget-container {
	border: none;
	border-radius: 3px;
}

.rw-widget-container.rw-state-focus,
.rw-state-focus>.rw-widget-container,
.rw-widget-container.rw-state-focus:hover,
.rw-state-focus>.rw-widget-container:hover {
	box-shadow: none;
	border: none;
}

.rw-list-option {
	color: #000;
	font-family: Roboto, sans-serif;
	font-size: 1em;
	line-height: 1.2em;
	font-weight: 300;
}

.rw-list-option:nth-child( 2n + 1 ) {
	background-color: #f8f8f8;
}

.rw-list-empty,
.rw-list-option,
.rw-list-optgroup {
	padding: .5em .75em;
	margin: .25em 1.25em 0 1.25em;
}

.rw-list-option.rw-state-selected,
.rw-list-option.rw-state-selected:hover,
.rw-list-option.rw-state-focus,
.rw-list-option.rw-state-focus:hover {
	background-color: #0099cc;
	border-color: #0099cc;
	color: #fff;
}

.rw-list-option:hover,
.rw-list-option:hover.rw-state.focus {
	background-color: #e5f4f9;
	border-color: #e5f4f9;
	color: #000;
}

.rw-placeholder {
	color: #848484;
}

.rw-popup-container {
	position: absolute;
	top: 97%;
	left: -6px;
	right: -6px;
}

.rw-popup-transition {
	padding: 0 6px;
}

.rw-popup-transition-exiting .rw-popup-transition,
.rw-popup-transition-exited .rw-popup-transition {
	box-shadow: 0 4px 4px 0 rgba(0,0,0,0.3);
}

.rw-popup {
	border: none;
	padding: 0.5em 0.625em 0.625em 0;
}

.rw-input,
.rw-filter-input {
	color: #000;
}

.rw-widget-input,
.rw-filter-input {
	box-shadow: none;
}

.rw-filter-input {
	border: 1px solid #9b9b9b;
	border-radius: 0;
	box-shadow: inset 2px 2px 4px 0 rgba(0,0,0,0.3);
	color: #848484;
	margin: 0 1.8em 0.5em 1.25em;
}

.rw-i-search {
	margin-bottom: 5px;
}

.rw-select {
	background-color: #fff;
	padding-top: 0.3em;
	padding-right: 0.3em;
}

.rw-i-caret-down:before {
	background: url( ../../img/dropdown-arrow-black.svg ) no-repeat;
	background-size: contain;

	width: 16px;
	height: 16px;
	content: '';
	display: block;
}

.rw-dropdown-list-input {
	background-color: #fff;
}

/* Multiselect specific */
.rw-multiselect.rw-widget {
	box-shadow: inset 2px 2px 4px 0 rgba(0,0,0,0.3);
}

.rw-multiselect {
	background-color: #fff;
	border-radius: 0;
	border: 1px solid #9B9B9B;
}

.rw-multiselect .rw-popup {
	border-radius: 0;
	border: 1px solid #9B9B9B;
	border-top: none;
	box-shadow: inset 4px 0 4px -2px rgba(0,0,0,0.3);
}


.rw-multiselect .rw-widget-picker > * {
	vertical-align: top;
}

.rw-multiselect .rw-widget-container {
	padding: 0.25em 0 0.25em 0.3em;
	border-radius: 0;
	background-color: initial;
}

.rw-multiselect .rw-popup-transition {
	padding: 0 5px;
}

.rw-multiselect .rw-input-reset {
	padding: 0 0.55em;
	color: #555;
	height: calc(2.429em - 8px);
	box-shadow: none;
}

.rw-multiselect-tag {
	background-color: #005773;
	border: none;
	border-radius: 2px;
	color: #fff;
	padding-right: 0;
}

.rw-multiselect-tag > span {
	line-height: 1.12em;
}

.rw-multiselect-tag button {
	border: none;
	text-align: center;
}

.rw-multiselect-tag button span {
	background-color: #004D66;
	font-weight: 300;
	font-size: 1.25em;
	line-height: 1em;
	padding: 0.25em;
}