/* Widgets Palette */
.widgets-palette-outer-container {
	background: #fff;
	position: absolute;
	top: 5.2rem;
	right: 0;
	height: calc( 100vh - 5.2rem );
	width: 20rem;
	padding: 1rem;
	box-shadow: 0px 3px 3px rgba( 0, 0, 0, 0.5 );
	overflow-y: auto;
	z-index: 10;
}

.widgets-palette-title {
	font-size: 1em;
	font-weight: 500;
	line-height: 1.25em;
	padding: 0.75em 1em;
	margin-top: 0;
	margin-bottom: 0.5em;
	border-bottom: 1px solid #b3b3b3;
	text-transform: uppercase;
}

.widget-in-palette {
	cursor: pointer;
	position: relative;
	max-width: 16em;
	margin: 2em 0;
	box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.05), 1px 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.widget-in-palette:hover {
	box-shadow: 2px 6px 12px 4px rgba(0, 0, 0, 0.05), 1px 1px 2px 0 rgba(0, 0, 0, 0.25);
}

.widget-in-palette-link {
	display: block;

}
.widget-in-palette-link:hover {
	background-color: rgba(0,0,0,0.7);
}
.widget-in-palette-name {
	width: 100%;
	height: 3.4em;
	bottom: 0;
	font-size: 1.065em;
	font-weight: 500;
	line-height: 1.25em;
	padding: 0.75em 1em;
	border-top: 1px solid #b3b3b3;
	text-transform: uppercase;
	background: #fff;
	color: #232527
}

.widget-in-palette-description {
	display: none;
	position: absolute;
	width: 100%;
	height: calc( 100% - 3.4em );
	padding: 2em 0.6em;
	color: #fff;
	background-color: rgba(0,0,0,0.7);
	text-shadow: 1px 1px #000;
}
.widget-in-palette:hover .widget-in-palette-description {
	display: block;
}

.widget-in-palette-img img {
	max-width: 100%;
	display: block;
}