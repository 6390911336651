.widget-configuration-form {
	background: #fff;
	position: absolute;
	top: 5.2rem;
	right: 0;
	height: calc( 100vh - 5.2rem );
	width: 19.5rem;
	padding: 1rem;
	box-shadow: 0px 3px 3px rgba( 0, 0, 0, 0.5 );
	overflow-y: auto;
	z-index: 10;
}

.widget-configuration-form input,
.widget-configuration-form textarea,
.widget-configuration-form select {
	display: block;
	width: 100%;
	margin-bottom: 1rem;
	line-height: 2.25rem;
	min-height: 2.25rem;
}

.validation-error {
	color: red;
	font-style: italic;
	font-size: 0.8em;
	/*background: #ffaaaa;*/
}

.prev-step-button {
	margin-right: 1em;
	margin-bottom: 1em;
}
.next-step-button {
	float: right;
	margin-right: 1em;
}

.text-field-label,
.textarea-field-label {
	margin-right: 1em;
	display: block;
}