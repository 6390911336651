.control-date-container.hide {
	visibility: hidden;
}

.control-date-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.control-date-container > div {
	/*margin-right: 11.2em;*/
	width: 50%;
}

.control-date-container > div:first-of-type {
	margin-right: 0.5em;
}

.control-date-result {
	font-size: 0.8em;
}

.control-date {
	display: flex;
	justify-content: space-between;
}