.dataset-configuration-info {
	display: inline-block;
	margin-left: 0.5em;
	position: relative;
}

.dataset-configuration-info .icon {
	font-size: 0.75em;
	background-color: #004d66;
	display: inline-block;
	color: #fff;
	width: 1.5em;
	height: 1.5em;
	line-height: 1.5em;
	border-radius: 1em;
	text-align: center;
	cursor: pointer;
	transform: translate( 0, -0.25em );
}

.dataset-configuration-info .popup {
	width: 10em;
	position: absolute;
	bottom: -0.5em;
	left: 2em;
	background: #fff;
	box-shadow: 0px 0px 3px rgba( 0, 0, 0, 0.3 );
	padding: 1em;
	z-index: 3;
}

.dataset-configuration-info .popup::before {
	content: '';
	position: absolute;
	bottom: 0.75em;
	left: -0.4em;
	display: inline-block;
	border: 0.5em solid black;
	border-color: transparent transparent #fff #fff;
	box-shadow: -2px 2px 3px rgba( 0, 0, 0, 0.1 );
	transform: rotate(45deg);
}
