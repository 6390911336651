/* mina: This block will be refactored when Wilfred is done with the design */
/*start*/
.csv-export-widget-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.csv-export-message {
	font-size: 0.8rem;
	color: #D0021B;

	height: 1.5rem;
	margin: 0.5rem 0 0.25rem;
	font-size: 0.8rem;
	/* z-index: 10; */
}
.csv-export-btn {
	width: 10rem;
	position: relative;
}

.csv-export-btn.disabled {
	opacity: 0.6;
	pointer-events: none;
	background-color: #004D66;
}

.calendar-day-currently-invalid {
	color: #fb3;
}
.react-datepicker__day--disabled.calendar-day-currently-invalid {
	color: #ccc;
}

.btn-loading:after {
	content: '';
	float: right;
	width: 1em;
	height: 1em;
	margin-left: 0.25em;
	background: url( ./../../img/spinner-gray.svg );
	-webkit-animation: spin 2s linear infinite;
	animation: spin 2s linear infinite;
}

/*end*/