.stylable-configuration h4 {
	margin: 0 0 0.25em;
}
.widget-configuration-form .stylable-color-configuration input {
	min-height: 30px;
	max-width: 93px;
}

.styling-picker-container {
	padding-top: 1em;
	border-top: 2px solid rgba( 0, 0, 0, 0.1 );
	margin-top: 1em;
	margin-bottom: 1em;
}