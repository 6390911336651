.dataset-item-container {
	position: relative;
	padding: 1rem 0.5rem;
	border: 1px solid #777;
	/* margin: 1rem 0; */
}

.dataset-item-container h4 {
	margin-top: 0;
	margin-bottom: 0.5rem;
}

.dataset-item-container .dataset-remove-icon {
	height: 2rem;
	width: 2rem;
	background: #c00;
	padding: 0.25rem 0.5rem;
	border-radius: 4px;
	position: absolute;
	top: 0.5rem;
	right: 0.5rem;
}
.dataset-remove-icon img {
	max-width: 100%;
}
.dataset-item-container .dataset-remove-icon.disabled {
	background-color: #aaa;
}

.datasets-tabs {
	position: relative;
	top: 1px;
	z-index: 1;
}

.dataset-tab {
	border: 1px solid #ccc;
	border-bottom: 1px solid #777;
	padding: 0.25rem 0.5rem;
}

.dataset-tab.add-new-tab {
	background: #eee;
}
.dataset-tab.active {
	background: #fff;
	border: 1px solid #777;
	border-bottom-color: #fff;
}
