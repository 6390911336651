.filter-type-selector {
	padding-top: 12px;
	display: flex;
}

.filter-type-item {
	font-size: 0.9em;
	display: flex;
	border: 1px solid #09c;
	height: 2.3rem;
	padding: 0.25rem;
	margin: 0.1rem;
	text-align: center;
	flex: 1;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	border-radius: 3px;
}

.filter-type-item:first-child{
	margin-left: 0;
}

.filter-type-item:last-child {
	margin-right: 0;
}
.filter-type-item.active {
	background: #09c;
	color: #fff;
}

.filter-type-item.disabled {
	cursor: default;
	color: #ccc;
	border-color: #555;
}