.dashboards-list li .delete-dashboard {
	display: none;
}
.dashboards-list li:hover .delete-dashboard {
	border-radius: 0;
	height: 2rem;
	width: 2rem;
	background: #c00;
	padding: 0.25rem 0.5rem;
	position: absolute;
	z-index: 999999;
	display: block;
	top: 0.5em;
	right: 0.5em;
}

.delete-dashboard img {
	width: 100%;
}

.dashboard-delete-confirmation-outer {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,0.7);
}

.dashboard-delete-confirmation {
	position: absolute;
	top: 0.5em;
	right: 0.5em;
	padding-left: 0.5em;
	z-index: 21;
	text-align: center;
	background-color: rgb(255, 255, 255);
	border: none;
	font-weight: bold;
}

.dashboard-delete-confirmation__text {
	margin-right: 0.5em;
}

.dashboard-delete-confirmation button {
	padding: 0.75em;
	/* margin-left: 0.5em; */
	/* margin-right: 0.5em; */
}

.dashboard-delete-confirmation button img {
	display: block;
	width: 0.75rem;
	height: 0.75rem;
}

.dashboard-delete-confirmation__confirm-button {
	background-color: #c00;
	border: none;
	/* border: 1px solid #fff; */
}

.dashboard-delete-confirmation__abort-button {
	background-color: #f4f4f4;;
	border: none;
	/* border: 1px solid #fff; */
	margin-right: 0;
}
.dashboard-delete-confirmation__abort-button img {
	transform: rotate( 45deg );
}