.configuration-steps {
	margin-bottom: 1em;
}

.configuration-step {
	width: 2em;
	height: 2em;
	color: #000;
	text-align: center;
	line-height: 2em;
	display: inline-block;
	margin-right: 0.5em;
	background-size: 70%;
	background-repeat: no-repeat;
	background-position: center;
	border-bottom: 2px solid #ccc;
	cursor: pointer;
}

.configuration-step.initial {
	color: transparent;
	background-image: url( './../../../img/fsi-title.svg' );
}

.configuration-step.stylable {
	color: transparent;
	background-image: url( './../../../img/fsi-brush.svg' );
}

.configuration-step.datasets {
	color: transparent;
	background-image: url( './../../../img/fsi-db.svg' );
}

.configuration-step.filled {
	border-bottom-color: #4CAF50;
}

.configuration-step.current {
	border-bottom-color: #09c;
}