.icon-indicator {
	/* height: 3em; */
	display: inline-block;
	/* width: 3em; */
	background: #eee;
	border: 4px solid #ccc;
	padding: 0.25em;
	margin: 0.1em 0;
}

.icon-picker-single-icon {
	display: inline-block;
	margin: 0.25rem 0.25rem 0 0;
	padding: 0.25em;
	border: 1px solid rgba(0, 0, 0, 0.5);
}

.icon-picker-single-icon.selected {
	border: 1px solid #09c;
	box-shadow: 0 0 4px #09c;
	background: rgba(0, 191, 255, 0.08);
}

.icon-picker-single-icon-inner {
	height: 2.5em;
	width: 2.5em;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
}

.no-icon-picker {
	float: left;
	padding-left: 0.2em;
}