.add-dashboard-name-form {
	transition: width 0.25s ease-in-out;
	width: 10rem;
	height: 100%;
	overflow: hidden;
	/* display: inline-block; */
	float: left;
}

.add-dashboard-name-form.zero-width {
	width: 0;
	margin-left: 0;
	margin-right: 0;
	padding-left: 0;
	padding-right: 0;
}
.add-dashboard-name-form input {
	width: 100%;
	height: 100%;
}

.dashboards-configuration-form-outer .problem {
	background: #cc0000;
}
.dashboards-configuration-form-outer .problem:hover {
	background: #990b0c;
}

.add-dashboard-name-form .invalid-field::placeholder {
	color: #cc0000;
}


.dashboards-configuration-form-outer > button .icon {
	transition: all 0.1s ease-in-out;
}

.dashboards-configuration-form-outer > button .icon.add-icon {
	padding: 0.15rem;
}

.dashboards-configuration-form-outer > button .icon.confirm-icon {
	padding: 0.2rem;
}

.dashboards-configuration-form-outer > button .icon.cancel-icon {
	padding: 0.15rem;
	transform: rotate( 45deg );
}