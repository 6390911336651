.react-datespicker-container {
	display: flex;
	align-items: center;
	margin-bottom: 0.4em;
}

.react-datespicker-container .react-datepicker-wrapper {
	width: 100%;
	position: relative;
}

.react-datespicker-container .react-datepicker-wrapper:first-of-type {
	margin-right: 0.5em;
}

.react-datespicker-container .react-datepicker-wrapper input {
	width: 100%;
}

.react-datespicker-container .react-datepicker-wrapper:after {
	content: '';
	display: block;

	position: absolute;
	top: 0.8em;
	left: 0.75em;
	cursor: pointer;

	background: url( ../../img/calendar.svg ) no-repeat;
	background-size: contain;

	width: 18px;
	height: 18px;

	pointer-events: none;
}

.react-datespicker-container input {
	display: block;
	width: 100%;
	padding: 0.75em 1.8em 0.75em 2.5em;
	border: none;
	border-radius: 3px;
	box-shadow: 0 2px 4px 0 rgba(0,0,0,0.3);
	font-family: TitilliumWeb, sans-serif;
	font-size: 0.88em;
	font-weight: 400;
	height: 3em;
	line-height: 3em;
	outline: none;
	color: #000;
	width: 70%;
}

.react-datespicker-container .close-icon {
	display: block;
	text-decoration: none;

	background-color: transparent;
	border: 0;
	cursor: pointer;
	height: 0;
	outline: 0;
	padding: 0;
	vertical-align: middle;
}

.react-datespicker-container .close-icon:after {
	background: #fff;
	border-radius: 50%;
	bottom: 0;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	color: #000;
	content: "\D7";
	cursor: pointer;
	font-size: 18px;
	height: 20px;
	width: 25px;
	line-height: 1;
	margin: -8px auto 0;
	position: absolute;
	right: 8px;
	text-align: center;
	top: 50%;
}
