.configuration-field-selector-list {
	margin: 0;
	list-style: none;
	padding: 0;
	/* border-left: 1px solid #ccc; */
	/* border-right: 1px solid #ccc; */
	border: 1px solid #ccc;
}

.configuration-field-selector-list-item {
	cursor: pointer;
	font-size: 1em;
	border-top: 1px solid #ccc;
	padding: 0.5em;
	padding-left: 1.5em;
	position: relative;
	overflow: hidden;
	text-overflow: ellipsis;
	padding-right: 1.25em;
	transition: all 0.25s linear;
	height: 2.25em;
}
.configuration-field-selector-list-item:first-child {
	border-top: none;
}
.configuration-field-selector-list-item::after {
	content: '';
	position: absolute;
	top: 0.6em;
	right: 0.5em;
	width: 1em;
	height: 1em;
	background: url( './../../../../img/dropdown-arrow-black.svg' );
	background-size: contain;
	transform: rotate( -90deg );
}

.configuration-field-selector-list-item.selected:before {
	content: '';
	position: absolute;
	top: 0.75em;
	left: 0.5em;
	width: 0.75em;
	height: 0.75em;
	background: url( './../../../../img/checkmark.svg' );
	background-size: contain;
	transition: all 0.25s linear;
	/* transform: rotate( -90deg ); */
}

/* Disabled Edit View View */
.edit-disabled .configuration-field-selector-list-item::after {
	display: none;
}
.edit-disabled .configuration-field-selector-list-item:not( .selected ) {
	transition: all 0.25s linear;
	transition-delay: 250ms;
	height: 0;
	padding: 0;
	opacity: 0;
	border-width: 0;
}

.edit-disabled {
	border-width: 0;
}
.edit-disabled .selected {
	border-width: 0;
	transform: translate( -0.5em, 0 );
}
