html {
	font-size: 16px;
}

body {
	background-color: #efefef;
	font-family: 'Roboto';
	font-size: 1rem;
	margin: 0;
}

* {
	box-sizing: border-box;
}

ol li,
ul li {
	font-size: 0.9rem;
}

::-webkit-scrollbar {
	width: 10px;
}
::-webkit-scrollbar-track {
	background-color: transparent;
}
::-webkit-scrollbar-thumb {
	background-color: #202325;
}
::-webkit-scrollbar-thumb:hover {
	background-color: #202325;
}

.resize-inactive,
.resize-active {
	border: 2px solid transparent;
	position: relative;
}

.resize-active:hover {
	border: 2px dashed #0099CC;
}

.resize-active .styled-widget:after {
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	content: '';
	background-color: #fff;
	width: 100%;
	height: 100%;
	opacity: 0.6;
	z-index: 99999;
	pointer-events: none;
}
.resize-active .leaflet-control,
.resize-active .cluster-content,
.resize-active .cluster-colors-container,
.resize-active .leaflet-marker-icon {
	pointer-events: none;
}

.background-disabled {
	position: fixed;
	width: 100%;
	height: calc( 100vh - 2.8em);
	left: 0;
	top: 2.8em;
	background-color: rgba(0,0,0,0.5);
	z-index: 11;
}

.react-grid-item.focused {
	z-index: 10;
}

.react-grid-item > .react-resizable-handle {
	background: transparent;
}
.react-resizable-handle:after {
	content: '';
	position: absolute;
	right: 0.6em;
	bottom: 0.6em;
	width: 0.6em;
	height: 0.6em;
	border-right: 0.15em solid #000;
	border-bottom: 0.15em solid #000;
}

/*BUTTONS*/
.btn {
	padding: 0.75em 1.35em;
	border: none;
	border-radius: 3px;
	color: #fff;
	background-color: #004D66;
	box-shadow: 0 3px 12px 0 rgba(0,0,0,0.05), 2px 2px 3px 0 rgba(0,0,0,0.05);
	font-size: 0.88em;
	font-weight: normal;
	line-height: 1.14em;
}

.btn-nostyle {
	border: none;
	background: transparent;
}

.btn-secondary {
	color: #004D66;
	background-color: #eee;
}

.logout-btn.btn {
	background: transparent;
	color: #fff;
}

/*BODY*/
.app-inner {
	height: 100vh;
	/*overflow-y: auto;*/
	background-size: cover;
	position: relative;
}

.app-inner-background {
	content: '';
	position: fixed;
	width: 100vw;
	display: block;
	height: 100vh;
	/*background-image: url(http://www.medef.com/uploads/media/node/0001/12/thumb_11956_node_958x544.png);*/
	background-size: cover;
	top: 0;
	left: 0;
	z-index: -1;
}

.site-container {
	width: 90%;
	margin: auto;
	padding: 5em 0 4em;
}

/*colors*/
.blue {
	background: #09c;
}
.light-green {
	background: #9c0;
}
.green {
	background: #690;
}
.pink {
	background: #f44;
}
.red {
	background: #c00;
}
.yellow {
	background: #fb3;
}
.orange {
	background: #f80;
}
.light-purple {
	background: #a6c;
}
.purple {
	background: #93c;
}

/* v2 styling */
.widget {
	float: left;
	margin: 1em;
	padding: 1em;
	box-sizing: border-box;
	background: #ccc;
}

.widget-header > h3 {
	/*font-size: 1.065em;*/
	font-size: 1em;
	font-weight: 500;
	line-height: 1.25em;
	padding: 0.75em 1em;
	margin-top: 0;
	margin-bottom: 0.5em;
	border-bottom: 1px solid rgba( 0, 0, 0, 0.3 );
	text-transform: uppercase;
}
.white-text .widget-header > h3 {
	border-bottom: 1px solid rgba( 255, 255, 255, 0.3 );
}

.widget-content-padder {
	padding: 0 1rem 1rem;
}

.react-grid-layout > .react-grid-item > div {
	background: #fff;
	box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.05), 1px 1px 2px 0 rgba(0, 0, 0, 0.05);
	box-sizing: border-box;

	/*overflow: auto;*/
	/* Removing it makes the date-pickers cropped */
	/* Adding it makes the widget unswipable */

	position: relative;

	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
}

.widget-wrapper .react-resizable-handle {
	display: none;
}

.widget-wrapper .loading:after {
	content: '';
	background: url( ../img/spinner-gray.svg );
	width: 20px;
	height: 20px;
	display: inline-block;

	-webkit-animation: spin 2s linear infinite;
	-moz-animation: spin 2s linear infinite;
	animation: spin 2s linear infinite;
}

.dashboard-editing .widget-wrapper .react-resizable-handle {
	display: block;
}

/* Stylable component styles  */
.react-grid-layout > .react-grid-item > div.split-view {
	display: flex;
	align-items: center;
	flex-direction: row;
}

.split-view .widget-header {
	width: 40%;
}

.split-view .widget-header > h3 {
	border-bottom: none;
	padding-top: 0;
	padding-left: 1.2em;
	padding-bottom: 0;
	margin-bottom: 0;
}


.split-view .sensor-overview-widget,
.split-view .current-value-widget {
	border-left: 1px solid rgba(0, 0, 0, 0.3);
	width: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.split-view.white-text .sensor-overview-widget,
.split-view.white-text .current-value-widget {
	border-left: 1px solid rgba(255, 255, 255, 0.5);
}

.split-view .current-value-text-container,
.split-view .sensors-overview-counts-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 0;
}


.split-view .current-value-text,
.split-view .sensors-overview-counts-container .devices-count {
	font-size: 2.5em;
}

.split-view .current-value-text-container .current-value-inner-content {
	line-height: .9em;
	font-family: TitilliumWeb, sans-serif;
}

.split-view .widget-background-icon,
.split-view .sensor-overview-widget ~ .widget-background-icon,
.split-view .current-value-widget ~ .widget-background-icon {
	position: inherit;
	top: unset;
	right: unset;
	bottom: unset;
	margin-right: 1.25em;
}

.no-heading .sensor-overview-widget ~ .widget-background-icon,
.no-heading .current-value-widget ~ .widget-background-icon {
	position: absolute;
	top: 0.5em;
	bottom: unset;
}

/*tooltip*/
/* Tooltip container */
.tooltip {
	position: relative;
	display: inline-block;
}

/* Tooltip text */
.tooltip .tooltip-text {
	visibility: hidden;
	width: 120px;
	background-color: #23282d;
	color: #fff;
	text-align: center;
	padding: 6px;
	border-radius: 6px;

	position: absolute;
	z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltip-text {
	visibility: visible;
}


/*spin animation*/
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }


button {
	cursor: pointer;
}

.hidden,
body .hidden {
	display: none;
}

.clearfix:after {
	content: '';
	display: block;
	clear: both;
}

.centered {
	text-align: center;
}

.maplese-header {
	display: flex;
	justify-content: space-between;
	width: 100%;
	height: 2.8em;
	background: #232527;

	position: fixed;
	z-index: 9990;
	margin-top: 0;
	top: 0;
	left: 0;
	right: 0;
	/*box-shadow: rgba(50,50,50,.4) 0 1px 2px 0px;*/

	-webkit-animation-name: slideInSticky; /* Chrome, Safari, Opera */
	-webkit-animation-duration: 0.8s; /* Chrome, Safari, Opera */
	animation-name: slideInSticky;
	animation-duration: 0.8s;
}


/* Chrome, Safari, Opera */
@-webkit-keyframes slideInSticky {
	0%   { top:-100px; }
	100% { top:0; }
}

/* Standard syntax */
@keyframes slideInSticky {
	0%   { top:-100px; }
	100% { top:0px; }
}

/* Login Form */
.login-blanket {
	background: url( ./../img/neurons.jpg );
	background-size: cover;
	position: fixed;
	width: 100%;
	height: 100%;
}

.login-popup {
	position: absolute;
	top: 50%;
	left: 45.85%;
	width: 28em;
	margin-left: -10em;
	min-height: 20em;
	margin-top: -10em;
	background-color: rgba( 3, 28, 38, 0.8 );
	color: #fff;
	padding: 1.625em 5.75em 2.625em;
}

.login-popup .maplese-logo {
	display: inline-block;
	height: 3em;
	width: 100%;
	background-image: url( .././img/maplese-logo-light.svg );
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	margin-bottom: 1.5em;
}

.login-popup label {
	margin-bottom: 1em;
	display: block;
	/*width: 100%;*/
}
.login-popup input[type=text],
.login-popup input[type=password] {
	display: block;
	margin-top: 6px;
	height: 2em;
	line-height: 2em;
	margin-bottom: 0.25em;
	width: 100%;
	background-color: #fff;
}

.login-btn {
	text-align: center;
}
.login-popup input[type=submit] {
	background-color: #005773;
	border: none;
	border-radius: 3px;
	box-shadow: 0 3px 12px 0 rgba(0,0,0,0.05), 2px 2px 3px 0 rgba(0,0,0,0.05);
	color: #fff;
	font-size: 0.9em;
	line-height: 1.12em;
	padding: 0.75em 0.6em;
	height: auto;
	width: 53%;
}

.login-popup .checkbox-label {
	line-height: 2.5em;
	display: flex;
	align-items: center;
}

.login-popup .checkbox-label input {
	background-color: #E5F4F9;
	box-shadow: none;
	width: 0.8em;
	height: 0.8em;
	margin: 0.15em 0.45em 0 0;
}

.login-error {
	background: #D0021B;
	padding: 1em;
	margin: 0.5em 0;
}

.logout-btn {
	padding: 0.9em 1.2em;
	min-width: 2.5em;
}


.dashboards-configuration-form-outer {
	position: relative;
	display: inline-block;
}

.header-notifications-container > button,
.dashboards-configuration-form-outer > button{
	float: left;
	display: block;
	padding: 0.9em 1.2em;
	min-width: 2.5em;
	background-color: #4A4A4A;
}
.header-notifications-container > button.active,
.dashboards-configuration-form-outer > button.active {
	background: #09c;
	position: relative;
}

.header-notifications-container > button:hover,
.dashboards-configuration-form-outer > button:hover,
.logout-btn:hover {
	background: #09c;
}

.header-notifications-container > button .icon,
.dashboards-configuration-form-outer > button .icon,
.logout-btn .icon {
	display: block;
	height: 1.3rem;
}

.dashboards-configuration-form {
	position: absolute;
	top: 100%;
	right: -6.6rem;
	padding: 1em 1em;
	border-top: 0.6em solid #09c;
	background: #202325;
	/*box-shadow: 1px 1px 10px #000;*/
}
.dashboards-configuration-form textarea {
	line-height: 1.25em;
	height: 76vh;
	width: 40vw;
	resize: none;
}

.dashboards-configuration-form button {
	margin-top: 1em;
}

/* Header Message */

.header-message {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	right: -8.22em;
	top: 3.5em;
	width: 100vw;
	height: 3em;
	background-color: #09c;
	color: #fff;
	font-size: 0.8rem;
}

.header-message .add-widget {
	padding: 0.25rem 0.5rem;
	background-color: #4CAF50;
}

.close-header-message {
	padding: 0.6em;
	margin: 0 1.8em;
	cursor: pointer;
	z-index: 9993;
}

.close-header-message .fa-times {
	background: url(../img/close-white.svg) no-repeat;
	background-size: contain;
	display: inline-block;

	width: 1.2em;
	height: 1.2em;
}

/* Header notifications */

.header-notifications-container {
	display: inline-block;
}


.header-notifications-inner-container {
	width: 0;
}

.header-notifications-list-container {
	width: 38em;
	transform: translate3d( calc( -100% + 6.6em ), 2.8em, 0 );
	padding: 0.8em;
	border-top: 0.6em solid #09c;
	background-color: #0A313F;
	max-width: 100vw;
}
.header-notifications-list-container.disabled {
	pointer-events: none;
}

.header-notifications-container > button {
	position: relative;
}

.header-notifications-container > button .notifications-count {
	position: absolute;
	background: #c00;
	color: #fff;
	text-shadow: 1px 1px 1px rgba( 0,0,0, 1 );
	border-radius: 2px;
	display: inline-block;
	padding: 0.2rem;
	top: 0.2rem;
	right: 0.2rem;
	min-width: 2.5em;
	font-size: 0.6rem;
}

.notifications-loading {
	margin: 0.8rem 0;
}

.notifications-list {
	max-height: calc( 100vh - 26em);
	padding: 0.6em;
	overflow: auto;
	background-color: #02161D;
	font-size: 0.9em;
}

.notifications-list::-webkit-scrollbar {
	width: 10px;
}
.notifications-list::-webkit-scrollbar-track {
	background-color: #02161D;
}
.notifications-list::-webkit-scrollbar-thumb {
	background-color: #4A4A4A;
}
.notifications-list::-webkit-scrollbar-thumb:hover {
	background-color: #4A4A4A;
}

.notifications-list-filters {
	display: flex;
	justify-content: space-between;
	margin-bottom: 0.8em;
	width: 100%;
}

.notifications-list-actions-filters {
	color: #fff;
}
.type-filters,
.general-filters {
	background-color: #02161D;
	border-radius: 3px;
	padding: 0.75rem 1rem;
}
.type-filters {
	display: flex;
	flex-direction: column;
	width: 70%;
	padding: 0.75rem;
}
.type-filters .container {
	display: flex;
	justify-content: flex-start;
}
.type-filters .filter-token {
	background-color: #0A313F;
	text-align: center;
	border-radius: 2px;
}

.filter-token {
	width: 7em;
	margin-right: 0.5rem;
	cursor: pointer;
	font-size: 0.875em;
}
.filter-token:last-of-type {
	margin-right: 0;
}
.filter-token-title {
	box-shadow: 0 3px 12px 0 rgba(0,0,0,0.05), 2px 2px 3px 0 rgba(0,0,0,0.05);
	padding: 0.2em;
	color: rgba(229, 244, 249, 0.75);
	font-weight: 500;
	/*font-size: 0.875rem;*/
	line-height: 1rem;
}

	.view-state-filters {
	margin-bottom: 0.3em;
	cursor: pointer;
}

.view-state-filters > div {
	display: flex;
	border-radius: 2px;
}

.view-state-filters .filter-token {
	padding: 0.35em 0.56em 0.35em 0;
	line-height: 1em;
}

.filter-token:before {
	display: block;
	height: 2em;
	width: 2em;
	margin: 0.4em auto;
}
.filter-communication:before {
	content: '';
	background: url( ./../img/network_white.svg ) no-repeat;
	background-size: contain;
}

.filter-limits:before {
	content: '';
	background: url( ./../img/warning-gauge_white.svg ) no-repeat;
	background-size: contain;
}
.filter-battery:before {
	content: '';
	background: url( ./../img/battery_white.svg ) no-repeat;
	background-size: contain;
}
.filter-other:before {
	content: '';
	background: url( ./../img/more_white.svg ) no-repeat;
	background-size: contain;
	background-position: center;
}
.type-filters .filter-token.active {
	background-color: #005773;
}

.type-filters .filter-token:hover {
	background-color: #005773;
}
.type-filters .filter-token:hover .filter-token-title {
	color: #fff;
}

.type-filters .filter-token.active .filter-token-title {
	color: #02161D;
	background-color: #09C;
}

.type-filters .filter-token.active:hover .filter-token-title {
	background-color: #00BFFF;
}

.general-filters {
	display: flex;
	flex-direction: column;
	width: 28%;
}
.filters-title {
	text-transform: uppercase;
	margin-bottom: 0.625em;
	display: flex;
	align-content: center;
}
.filters-title .icon {
	background-size: contain;
	background-repeat: no-repeat;
	width: 1rem;
	height: 1rem;
	margin-top: 1px;
	margin-right: 5px;
}
.type-filters .filters-title .icon {
	background-image: url( ../img/filter-white-outline.svg );
}
.general-filters .filters-title .icon {
	background-image: url( ../img/view.svg );
}

.general-filters .filter-all:hover .filter-checkbox,
.general-filters .filter-new:hover .filter-checkbox {
	border: 1px solid #00BFFF;
}

.notifications-list-filters .filter-all {
	background-color: #0A313F;
	margin-bottom: 0.8em;
}
.notifications-list-filters .filter-new {
	text-shadow: 0px 0px 6px #000;
	background-color: #09c;
}

.notifications-list-tools {
	display: flex;
	justify-content: space-between;
}

.notifications-list-actions {
	display: flex;
	border-left: 1px solid transparent;
	padding: 0.8em;
}
.select-checkbox {
	display: flex;
	align-items: center;
	margin-right: 3em;
	line-height: 1em;
	cursor: pointer;
}

.select-all-pages-link {
	color: #005775;
	text-decoration: underline;
}

.selection-info {
	padding: 0.625em 1em;
	margin-bottom: 0.75em;
	border-radius: 0.1em;
	background-color: #004D66;
	color: #00f4ff;
	border-radius: 3px;
	line-height: 1.5em;
}
.selection-info.no-selection {
	color: #ccc;
}


.available-actions-container > span {
	display: inline-block;
	text-decoration: underline;
	margin-left: 5em;
	color: #E5F4F9;
	cursor: pointer;
}

.confirm-bulk-delete-container {
	display: inline-block;
	color: #b6edff;
	font-style: italic;
}

.confirm-bulk-delete-actions button {
	border: none;
	padding: 0.125rem 0.5rem;
	color: #fff;
	margin-left: 0.5em;
	background: #09c;
}
.confirm-bulk-delete-actions .confirm-bulk-delete-button {
	background: #D0021B;
}

.confirm-bulk-delete-container,
.bulk-delete-confirm .available-actions-container > span {
	margin-left: 2.5em;
}


.header-notification-item {
	background-color: #06242e;
	border-left: 2px solid transparent;
	border-radius: 2px;
	color: #fff;
	margin-bottom: 0.6em;
	font-size: 0.9em;
	display: flex;
	justify-content: space-between;
}
.header-notification-item:last-of-type {
	margin-bottom: 0;
}

.notification-checkbox-wrapper {
	padding: 0.8em 0 0.8em 0.6em;
}

.notification-item-wrapper {
	cursor: pointer;
	display: flex;
	width: 90%;
	padding: 0.8em 0.8em 0.8em 0;
}

.header-notification-item .other-rows {
	display: none;
}
.header-notification-item > input {
	height: 2em;
	position: absolute;
	top: 50%;
	margin-top: -1em;
	left: 0.3em;
}
 .notification-arrow-wrapper {
	cursor: pointer;
	background-color: #125067;
	width: 7%;
	padding: 0.8em;
	display: flex;
	justify-content: center;
	align-items: flex-start;
}
.selected .notification-arrow-wrapper {
	background-color: #125067;
}
.unseen .notification-arrow-wrapper {
	background-color: #007297;
}
.selected.unseen .notification-arrow-wrapper {
	background-color: #007297;
}

.header-notification-item .arrow {
	display: inline-block;
	width: 1.5em;
	height: 1.5em;
	background-image: url( ../img/dropdown-arrow.svg );
	background-size: contain;
	background-repeat: no-repeat;
	cursor: pointer;
}
.header-notification-item.expanded .arrow {
	transform: rotate(180deg);
}

.select-checkbox:before,
.header-notification-item .notification-checkbox,
.filter-checkbox {
	background-color: #E5F4F9;
	cursor: pointer;
	border-radius: 1px;
	margin-right: 1em;
}
.select-checkbox .filter-checkbox {
	width: 1rem;
	height: 1rem;
	flex: 0 0 1rem;
	margin-right: 0.75rem;
}

.filter-checkbox {
	width: 1.5rem;
	height: 1.5rem;
	flex: 0 0 1.5rem;
}


.header-notification-item .notification-checkbox {
	display: block;
	width: 1.25rem;
	height: 1.25rem;
	border: 1px solid transparent;
	margin-right: 0.625rem;
}

.header-notification-item.unseen {
	background-color: #0e4559;
	border-left: 2px solid #00f4ff;
	font-weight: 600;
	letter-spacing: 0.3px;
}

.header-notification-item.selected {
	background-color: #0099cc;
}

.header-notification-item.unseen.selected {
	background-color: #0099cc;
}

.header-notification-item.selected .notification-checkbox,
.active .filter-checkbox {
	background-color: #FFFFFF;
}

.active .filter-checkbox {
	display: block;
}

.header-notification-item.selected .notification-checkbox:after,
.active .filter-checkbox:after {
	content: '';
	display: block;
	width: 70%;
	height: 70%;
	margin-top: 15%;
	margin-left: 15%;

	background-image: url( ../img/checkmark.svg );
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
}

.header-notification-item .notification-text-content {
	text-shadow: 0px 0px 6px rgba( 0, 0, 0, 0.5 );
}
.header-notification-item .main-row {
	line-height: 1.25rem;
}

.header-notification-item.expanded {
	width: 100%;
}

.header-notification-item.expanded .other-rows {
	display: block;
}

.notification-misc-container {
	flex: 1 0 4em;
}

.notification-timestamp {
	color: #b6edff;
	line-height: 1.25rem;
	font-size: 0.8em;
	text-align: right;
}

.header-notification-item .delete-notification {
	background: url(../img/delete-white.svg) no-repeat;
	background-size: contain;
	margin-top: 0.8em;
	float: right;
	height: 1.4em;
	width: 1.2em;
	cursor: pointer;
}

.header-notification-item .confirm-delete {
	text-align: right;
	line-height: 1.25rem;
	min-width: 8rem;
}

.header-notification-item .confirm-delete-notification-question {
	color: #b6edff;
	font-weight: normal;
	font-style: italic;
}

.confirm-delete-actions {
	text-align: right;
	margin-top: 0.5em;
}

.header-notification-item .confirm-delete-notification,
.header-notification-item .abort-delete-notification {
	display: inline-block;
	padding: 0.125rem 0.5rem;
	border: none;
	color: #fff;
}

.confirm-delete-notification {
	background: #D0021B;
	margin-right: 0.5rem;
}

.abort-delete-notification {
	background: #09c;
}

.no-notifications-message {
	color: #73bf21;
	background: rgba(115, 191, 33, 0.1);
	padding: 0.5em 1em;
	border: 1px solid #73bf21;
}

.notifications-pagination {
	background-color: #02161D;
	float: right;
	border-radius: 3px 3px 0 0;
	padding: 0.8em;
}

.previous-notifications,
.next-notifications {
	display: inline-block;

	width: 3em;
	height: 3em;
	padding: 0.75em;

 	border-radius: 3px;
	color: #fff;
	background-color: #005773;
	box-shadow: 0 3px 12px 0 rgba(0,0,0,0.05), 2px 2px 3px 0 rgba(0,0,0,0.05);
}

.next-notifications {
	margin-left: 0.8em;
}
.next-notifications:after,
.previous-notifications:before {
	content: '';
	display: inline-block;
	width: 1.5em;
	height: 1.5em;
	background-image: url( ../img/dropdown-arrow.svg );
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
}

.next-notifications:after {
	transform: rotate(-90deg);
	margin: 0 -0.4em;
}

.previous-notifications:before {
	transform: rotate(90deg);
	margin: 0 -0.6em;
}

.previous-notifications.disabled,
.next-notifications.disabled {
	opacity: 0.6;
	pointer-events: none;
	background-color: #004D66;
}

.open-details-popup {
	cursor: pointer;
}

.logo-container {
	width: 7em;
	height: 2.2em;
	margin: 0.6em 0.4em 0 0.6em;
	background: url('./../img/maplese-logo-light.svg');
	background-size: contain;
	background-repeat: no-repeat;
}

.header-menu {
	text-transform: uppercase;
	color: #fff;
}

.toggle-header-menu {
	display: flex;
	justify-content: center;
	width: 22em;
	padding: 0.8em;
	cursor: pointer;
}
.current-dashboard {
	display: flex;
	justify-content: center;
	width: 22em;
	padding: 0.8em;
	cursor: default;
}
.toggle-header-menu span {
	max-width: 16em;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.current-dashboard:before,
.toggle-header-menu:before,
.toggle-header-menu:after {
	content: '';
	display: inline-block;
	width: 1.2rem;
	height: 1.2rem;
	margin: 0 0.7em 0 0.6rem;
}
.current-dashboard:before,
.toggle-header-menu:before {
	background-image: url( ../img/dashboard.svg );
	background-size: contain;
}
.toggle-header-menu:after {
	background-image: url( ../img/dropdown-arrow.svg );
	background-size: contain;
}
.toggle-header-menu:not( .closed ):after {
	transform: rotate( 180deg );
}

.header-menu-list {
	position: absolute;
	margin: 0;
	padding: 0;
	list-style: none;
	text-align: center;
	background-color: #232527;
	box-shadow: 0 1px 2px 0 rgba( 0, 0, 0, 0.13 );
	z-index: 100;
	font-size: 1rem;
}

.header-menu .header-menu-item {
	display: block;
	width: 22em;
	padding: 0.8em;
	color: #fff;
	text-decoration: none;
	font-size: 1rem;
}
.header-menu a.header-menu-item:hover,
.header-menu a.header-menu-item.active {
	background-color: #09c;
}

.header-right-menu {
	display: flex;
}

.dashboards-list ul {
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	list-style: none;
	padding-left: 0;
}
.dashboards-list li {
	position: relative;
	width: 18rem;
	height: 15rem;
	margin: 0.5rem;
	background-color: #fff;
	background-image: url(../img/default-dashboard.svg);
	background-size: contain;
	box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.05), 1px 1px 2px 0 rgba(0, 0, 0, 0.05);
}
.dashboards-list li:hover {
	/*transform: scale( 1.1 );*/
	box-shadow: 2px 6px 12px 4px rgba(0, 0, 0, 0.05), 1px 1px 2px 0 rgba(0, 0, 0, 0.25);
}
.dashboard-link {
	position: relative;
	display: block;
	width: 100%;
	height: 100%;
}
.dashboard-link:hover {
	background-color: rgba(0,0,0,0.7);
}
.dashboard-link-title {
	position: absolute;
	display: flex;
	align-items: center;
	width: 100%;
	height: 3.4em;
	bottom: 0;
	font-size: 1.065em;
	font-weight: 500;
	line-height: 1.25em;
	padding: 0.75em 1em;
	border-top: 1px solid #b3b3b3;
	text-transform: uppercase;
	background: #fff;
	color: #232527;
}
.open-dashboard-icon {
	display: none;
	position: absolute;
	top: 35%;
	left: calc( 50% - 1em );
	width: 2em;
	height: 2em;
	background-image: url( ../img/view.svg );
	background-size: contain;
}
.dashboard-link:hover .open-dashboard-icon {
	display: block;
}

/* New Dashboard Form */
.new-dashboard-form {
	text-align: left;
	display: inline-block;
	width: 14em;
	padding: 1em;
	background-color: #09c2;
}

.new-dashboard-form input {
	width: 100%;
	margin-bottom: 0.5em;
}
.new-dashboard-form button {
	width: 100%;
}

/*POPUP*/
.widget-popup-blanket {
	position: fixed;
	height: 100%;
	width: 100%;
	background: #fff;
	opacity: 0.5;
	z-index: 9991;
	top: 0;
	left: 0;
}

.widget-popup {
	z-index: 9992;
	position: fixed;
	height: 80vh;
	width: 90vw;
	left: 5vw;
	background: white;
	overflow: auto;
	box-shadow: 4px 1px 4px 5px rgba( 0, 0, 0, 0.13 );
	border-radius: 5px;
	border-bottom-left-radius: 0;
}

.close-widget-popup {
	position: absolute;
	top: 0.5em;
	right: 0.5em;
	padding: 0.6em;
	cursor: pointer;
	z-index: 9993;
}

.close-widget-popup .fa-times {
	background: url(../img/fa-times.svg) no-repeat;
	background-size: contain;
	display: inline-block;

	width: 1em;
	height: 1em;
}

/*chart widgets*/
.react-grid-item > div .scatter-legend {
	display: flex;
	flex-wrap: wrap;
	box-shadow: none;
	float: none;
}
.react-grid-item > div .scatter-legend li {
	padding: 4px 10px;
}

.chart-widget {
	display: flex;
	flex-direction: column;
	height: calc( 100% - 3.5em );
}

.chart-container {
	padding-top: 1em;
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}

.multiple-realtime-line-chart {
	height: 100%;
}

.no-data-selected {
	text-align: center;
}

.chart-container .no-chart-data {
	text-align: center;
}

.c3-timeseries-chart,
.c3-multiple-ts-charts {
	width: 100%;
	height: calc( 100% - 3.5em );
}

.chart-canvas-container {
	width: 100%;
	height: 100%;
	text-align: center;
}

.c3-tooltip-container {
	color: #000;
}

.widget-title,
.chart-widget-title {
	margin-bottom: 3px;
	font-weight: 300;
}

/*table*/
table {
	width: 100%;
}

table th,
table td {
	padding: 8px;
	text-align: left;
	font-size: 1rem;
}
.name-cell {
	width: 50%;
}
.header-cell {
	padding: 14px 8px;
}
tr:nth-child(even) {
	background-color: rgba(188, 188, 188, 0.04);
}
tr:nth-child(odd) {
	background-color: rgba(159, 159, 159, 0.06);
}

.alternating-list li:nth-child(even) {
	background-color: #f8f8f8;
}
.alternating-list li:nth-child(odd) {
	background-color: #fcfcfc;
}

/*Trace monitoring widget*/
.value-in-square {
	position: relative;
	width: 100%;
	height: 40px;

	background: #bfbfbf;
	color: #fff;
	text-align: center;
	/*in ie the animation doesn't stop*/
	-webkit-animation: none;
	-moz-animation: none;
	animation: none;
	display: inline-block;
}
.value-in-square.yellow {
	background: #fb3;
	color: #000;
}
.value-in-square.red {
	background: #c00;
}
.value-in-square:before {
	content: '';
	position: absolute;
	width: 8px;
	height: 100%;
	left: 0;
	background: rgba( 0, 0, 0, 0.2 );
}
.value-in-square div {
	padding-top: 8px;
}
.value-in-square.loading {
	background: url( '../img/spinner-gray.svg' );
	width: 20px;
	height: 20px;
	background-size: contain;
	-webkit-animation: spin 2s linear infinite;
	-moz-animation: spin 2s linear infinite;
	animation: spin 2s linear infinite;
}
.value-in-square.loading:before {
	display: none;
}

/* table with fixed header */
.table-fixed-header tr {
	display: flex;
}
.table-fixed-header td {
	flex: 1 auto;
	word-wrap: break-word;
}
.table-fixed-header thead tr:after {
	content: '';
	overflow-y: scroll;
	visibility: hidden;
	height: 0;
}
.table-fixed-header thead th {
	flex: 1 auto;
	display: block;
}
.table-fixed-header tbody {
  display: block;
  width: 100%;
  overflow-y: auto;
  height: 250px;
}

/* State monitoring widget */
.react-grid-layout > .react-grid-item > div > .react-grid-item-content.monitoring-widget {
	overflow-x: auto;
	overflow-y: hidden;
}
.state-monitoring-widget {
	display: flex;
	flex-direction: column;
	height: calc( 100% - 4em );
}

.monitoring-widget-table-container {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	overflow-y: auto;
	position: relative;
}
.monitoring-widget-table-container.loading {
	align-items: center;
    justify-content: center;
}

.monitoring-widget-table-container.loading .monitoring-widget-table {
	display: none;
}

.monitoring-widget-table {
	width: 100%;
	padding: 15px;
}

.monitored-device-item,
.monitoring-list-header {
	display: flex;
	align-items: center;
	margin-bottom: 1em;
}

.monitoring-widget-table .header-cell,
.monitoring-widget-table .table-cell {
	width: 25%;
	padding: 0 0.375em;
}
.monitoring-widget-table .header-cell {
	font-weight: bold;
}

.monitoring-widget-table .name-cell {
	width: 50%;
}

.trace-monitoring-widget-table.table-fixed-header tbody {
	height: 525px;
}
.monitoring-filters {
	padding: 1em 1.5em 2.5em;
}
.monitoring-filters {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	font-size: 1rem;
}
.monitoring-filters-title {
	display: flex;
	margin-top: 4px;
	margin-bottom: 10px;
}
.monitoring-filters-title:before {
	content: '';
	padding-right: 4px;

	display: inline-block;
	background: url( ../img/filter.svg ) no-repeat;
	background-size: contain;
	width: 22px;
	height: 22px;
	margin-top: -2px;
}

.monitoring-filters-list {
	flex: 1;
	display: flex;
	margin: 0;
}
.monitoring-filter-item {
	flex: 1;
	position: relative;
	display: inline-block;
	max-width: 75px;
	height: 36px;
	margin: 0 6px;
	opacity: 0.3;
	cursor: pointer;
}
.monitoring-filter-item:before {
	content: '';
	position: absolute;
	width: 8px;
	height: 100%;
	left: 0;
	background: rgba( 0, 0, 0, 0.2 );
}
.monitoring-filter-item:hover,
.monitoring-filter-item.selected {
	opacity: 1;
}
.monitoring-filter-item.with-checkmark:after {
	content: '';
	display: block;
	width: 16px;
	height: 16px;
	margin: 8px 1.5em;
	background: url(../img/checked_white.svg);
	background-size: contain;
}

 @media ( max-width: 480px ) {
	.login-popup {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 20em;
		padding: 1em 2em 2em;
	}

	.monitoring-filters-list {
		padding: 0;
	}
}
/*scrollbar*/

/*spin animation*/
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }


.comparison-widget,
.csv-exporter-widget {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	height: 100%;
}

/*settings date pickers*/
.datetime-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.react-datepicker__input-container {
	width: 100%;
}

/* SENSOR LOCATOR WIDGET */
.locator-widget {
	display: flex;
	flex-direction: column;
	height: calc( 100% - 3.5em );
}

.map-container {
	width: 100%;
	height: 300px;
	/*height: 225px;*/
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}
.popup-markup-container .map-container {
	height: 100%;
}

.map {
	width: 100%;
	height: 100%;
	display: block;
	right: 0;
	background: white;
}

.map-fullscreen-icon,
.map-clustering-icon {
	position: relative;
	cursor: pointer;
}
.map-fullscreen-icon:after {
	content: '';
	position: absolute;
	width: 14px;
	height: 14px;
	top: 6px;
	left: 6px;
	background: url( ../img/fullscreen.svg ) no-repeat;
	background-size: contain;
}
.expanded .map-fullscreen-icon:after,
.map-fullscreen-icon.popup-opened:after {
	background: url( ../img/smallscreen.svg ) no-repeat;
	background-size: contain;
}

.leaflet-touch .map-fullscreen-icon:after {
	top: 8px;
	left: 8px;
}

.map-clustering-icon:before {
	content: '10';
    position: absolute;
    width: 20px;
    height: 20px;
    top: 3px;
    left: 3px;
    background: #000;
    border-radius: 50%;
    color: #fff;
    font-size: 10px;
    line-height: 20px;
}
.leaflet-touch .map-clustering-icon:before {
	top: 5px;
	left: 5px;
}

.map-clustering-icon.disabled:before {
    background: #a7a7a7;
}

/*clusters*/
.my-div-cluster .cluster-content{
	width: 33px !important;
	height: 30px !important;
	text-align: center;
	font-size: 16px;
	padding-top: 3px;
	background-color: #000;
	color: white;
	border-radius: 50%;
}

.my-div-cluster.big-cluster {
	width: 48px !important;
	height: 40px !important;
	padding-top: 8px;
}

.my-div-cluster.bigger-cluster {
	width: 50px !important;
	height: 50px !important;
	padding-top: 13px;
	background-size: 50px 50px !important;
}

.cluster-colors-container {
	width: 30px;
	margin-left: 15px;
	margin-top: -10px;
}
.cluster-color-circle {
	display: inline-block;
	width: 10px;
	height: 10px;
	border-radius: 50%;
}

.map-legend {
	min-width: 8em;
	max-height: 90%;
	/*max-height: 12em;*/
	overflow: auto;
	padding: 0.6em;
	margin: 0;
	border-radius: 4px;
	background: rgba(255,255,255,0.8);
	color: #000;
	box-shadow: 0 0 15px rgba(0,0,0,0.2);
}
.map-legend li {
	display: flex;
	align-items: center;
	font-size: 0.8rem;
}
.legend-color {
	width: 1.2em;
	height: 1.2em;
	margin-right: 0.6em;
}

.property-value-tooltip {
	min-width: 4em;
	text-align: center;
	font-weight: bold;
	color: red;
}


/*overview widget*/
.sensors-overview-counts-container {
	padding-top: .5em;
	padding-left: 1.25em;
	padding-right: 1.25em;
	display: flex;
	flex-direction: column;
	/*justify-content: space-between;*/
	/*align-items: top;*/
}

.sensors-overview-counts-container .devices-count {
	cursor: pointer;
	font-family: TitilliumWeb, sans-serif;
	font-weight: normal;
	font-size: 3.5em;
	line-height: .9em;
}
.sensors-overview-counts-container a {
	color: inherit;
	text-decoration: none;
}

.sensor-overview-widget {
	height: calc( 100% - 3.5em );
}

.sensor-overview-widget.widget-subtitle {
	font-size: 14px;
	margin-top: 3px;
	/* Text shadow needed due to overlapping over the background image */
	text-shadow: 1px 1px 2px #fff;
}
.sensor-overview-widget.white-text .widget-subtitle {
	text-shadow: 1px 1px 2px #000;
}

.sensor-overview-widget ~ .widget-background-icon,
.current-value-widget ~ .widget-background-icon {
	position: absolute;
	top: 3.5em;
	right: 1.25em;
}

.sensor-overview-widget .subtitle-blanket {
	background: rgba( 255, 255, 255, 0.5 );
}

.sensor-overview-widget.white-text .subtitle-blanket {
	background: rgba( 0, 0, 0, 0.2 );
}

.popup-markup-container {
	height: 100%;
	display: flex;
}

.sow-popup-location-container {
	height: 200px;
	width: 300px;
	float: left;
}

.leaflet-container {
	width: 100%;
	height: 100%;
	display: block;
	right: 0;
	background: white;
}

.leaflet-container.leaflet-touch .leaflet-bar {
	border: none;
	box-shadow: 0 1px 5px rgba( 0,0,0,0.65 );
}

.sow-widget-popup-search-sidebar {
	flex: 0 0 auto;
	width: 17%;
	height: 100%;
	overflow: auto;
}

.sow-sensors-list::-webkit-scrollbar {
	width: 10px;
}
.sow-sensors-list::-webkit-scrollbar-track {
	background-color: #333333;
}
.sow-sensors-list::-webkit-scrollbar-thumb {
	background-color: #d8d8d8;
}
.sow-sensors-list::-webkit-scrollbar-thumb:hover {
	background-color: #d8d8d8;
}

.popup-header-title {
	margin-top: 0;
	font-size: 26px;
	padding-top: 1em;
	padding-right: 20px;
	display: inline-block;
	margin: 0;
}

.popup-current-sensor-name {
	display: inline-block;
	color: #656565;
	font-size: 20px;
	font-weight: normal;
	margin: 0;
}

.sow-filter-input {
	background: #f3f3f3;
	padding: 15px 20px;
	height: 66px;

}
.sow-filter-input input {
	background: transparent;
	border: none;
	border-bottom: 2px solid #979797;
	box-shadow: none;
	width: calc( 100% - 20px );
	display: block;
	margin: 0 0 0 20px;
	padding: 7px 0;
	font-size: 16px;
}

.popup-filter-search-icon {
	position: absolute;
	margin-top: 10px;
	margin-left: -8px;

	background: url(../img/fa-search.svg) no-repeat;
	background-size: contain;

	width: 1.2em;
	height: 1.2em;
}

.sow-filter-input input:focus {
	outline-width: 0;
	border-bottom: 2px solid #09c;
}

.sow-filter-input input::-webkit-input-placeholder,
.sow-filter-input input:-moz-placeholder,
.sow-filter-input input::-moz-placeholder,
.sow-filter-input input:-ms-input-placeholder {
   color: #4a4a4a;
}

.sow-sensors-list {
	height: calc( 100% - 66px );
	overflow: auto;
}

.no-sensor-selected-header {
	color: #656565;
	font-weight: normal;
}

.sow-sensors-list ul {
	list-style: none;
	color: #fff;
	background: #202325;
	margin: 0;
	padding: 0;
	padding-top: 15px;
	min-height: 100%;
}
.sow-sensors-list li {
	cursor: pointer;
	padding: 0.75em 20px;
	font-weight: 300;
	font-size: 1rem;
	text-overflow: ellipsis;
	overflow: hidden;
}
.sow-sensors-list li.active {
	background: #09c;
}

.sow-sensors-popup-main-content {
	flex-grow: 1;
	height: 100%;
	overflow: auto;
	padding-left: 20px;

	flex: 0 0 auto;

	display: flex;
	flex-flow: row wrap;
}

.sow-sensors-popup-main-content.with-sidebar {
	width: 83%;
}
.sow-sensors-popup-main-content.no-sidebar {
	width: 100%;
}

.sow-popup-property-history-container,
.sow-string-property-overview,
.sow-complex-property-overview {
	box-shadow: 2px 2px 5px 3px rgba( 0, 0, 0, 0.1 );
	overflow: auto;
	width: 100%;
	height: 100%;
	padding: 0 1em;
}

.sow-popup-properties-container,
.sow-popup-location-container ,
.sow-popup-latest-messages-container {

	float: left;
	width: 48%;
	box-shadow: 2px 2px 5px 3px rgba( 0, 0, 0, 0.1 );
	overflow: auto;
}

.sow-popup-properties-container {
	width: 100%;
	height: 60%;
	flex: 0 0 auto;
	margin: 0 0 4.5% 0;
}

.sow-popup-location-container,
.sow-popup-latest-messages-container {
	height: 36%;
	width: 48%;
	flex: 0 0 auto;
	align-self: flex-start;
}

.sow-popup-properties-container.empty {
	background: url( ../img/property-table-background-s.png );
}
.sow-popup-properties-container.empty:after {
	content: 'No properties to show';
	color: #656565;
	padding: 10px;
	display: inline-block;
}

.sow-popup-latest-messages-container.empty {
	background: url( ../img/communication-messages-placeholder.png );

}
.sow-popup-properties-container td {
	cursor: pointer;
	font-size: 1rem;
}

.sow-popup-location-container.empty,
.sow-popup-location-container.empty .map{
	background: url( ../img/popup-location-placeholder.png );
	background-size: cover;
}

.loading-communication-history,
.sow-popup-latest-messages-container.empty:after {
	content: 'No communication data';
	color: #656565;
	display: inline-block;
	padding-left: 20px;
	font-weight: normal;
	margin: 20px 0;
	font-size: 1.17em;

}

.sow-popup-properties-container.empty:after {
	content: 'No properties to show';
	color: #656565;
	padding: 10px;
	display: inline-block;
}

.sow-popup-latest-messages-container h3 {
	padding-left: 20px;
	font-weight: normal;
	margin: 20px 0;
	font-size: 1.17em;
}

.sow-popup-latest-messages-container ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

.sow-popup-latest-messages-container li {
	padding: 20px 20px 15px;
	font-size: 1rem;
}

.popup-property-table {
	width: 100%;
}

.property-table-row.active {
	background: #09c;
	color: #fff;
	font-weight: 100;
}

.popup-property-table td:first-child {
	padding-left: 15px;
}

.popup-property-table td:last-child {
	padding-right: 15px;
}

/* property history container */
.sow-popup-property-history-container {
	height: 100%;
	width: 100%;
	flex: 0 0 auto;

	display: flex;
	flex-direction: column;

	padding: 0 15px;
}

.sow-popup-overview-header {
	height: 65px;
	flex: 0 0 100%; /* Let it fill the entire space horizontally */
}

.sow-popup-content-left-column,
.sow-popup-content-right-column {
	padding-bottom: 2%;
	margin: 0 2% 0 0;

	align-content: flex-start;
	flex: 0 0 auto;
	width: 48%;
	height: calc( 100% - 65px );

	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	align-items: flex-start;
}
.sow-popup-content-left-column.hidden {
	display: none;
}

.expanded {
	width: 98%;
}

.expanded .sow-popup-location-container {
	width: 100%;
	height: 100%;
}

.expanded .sow-popup-properties-container,
.expanded .sow-popup-latest-messages-container {
	display: none;
}

.popup-property-history-chart-container {
	width: 100%;
	height: calc( 100% - 60px );
}

.expanded .popup-property-history-chart-container {
	padding: 0 5% 0 0;
}
.location-history:after {
	background: url( '../img/history.svg' ) no-repeat;
	background-size: contain;
}
.expanded .location-history:after {
	background: url( '../img/smallscreen.svg' ) no-repeat;
	background-size: contain;
}

.sow-popup-property-history-container .chart-hint {
	margin: 15px 0;
	position: relative;
	cursor: default;
}

.chart-hint .icon {
	width: 14px;
	height: 14px;
	position: absolute;
	top: 2;
	right: 0;
	cursor: pointer;
}
.expand-icon {
	width: 14px;
	height: 14px;
	background: url( ../img/fullscreen.svg ) no-repeat;
	background-size: contain;
}

.sow-popup-property-history-container.empty {
	background: url( ../img/popup-chart-background.png ) no-repeat;
}

.sow-popup-property-history-container.empty .chart-hint .icon {
	background: none;
}

.expanded .chart-hint .icon {
	background: url( ../img/smallscreen.svg ) no-repeat;
	background-size: contain;
}

.popup-property-history-chart-container .chart-legend {
	display: none;
}

.popup-property-history-inner-container.chart-initialized {
	background: #fff;
	height: 100%;
}

.property-chart-helper.widget-loading > div {
	display: none;
}

.property-chart-helper {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
}

.property-chart-helper .datetime-container {
	display: flex;
	width: 100%;
	/*height: 30%;*/
	/*height: 20%;*/
	flex: 0 0 auto;
}

.expanded .property-chart-helper .datetime-container {
	width: 50%;
}

.property-chart-helper .chart-canvas-container {
	height: 70%;
	width: 100%;
	flex: 0 0 auto;
	/*align-self: flex-start;*/
	align-content: space-between;
}

.property-chart-helper .calendars-container {
	width: 48%;
	height: 100%;
}

.property-chart-helper .vso-datespicker-container {
	flex-direction: column;
}

.property-chart-helper .vso-rangepickers-container > div:first-of-type {
	margin-right: 0;
	margin-bottom: 1em;
}

.property-chart-helper .period-facets-container {
	width: 50%;
	display: flex;
	flex-direction: column;
}

/* So ashamed of this hack right now... :/ */
.sow-complex-property-json-tree ul > li > ul > li > label:first-child {
	margin-left: -1em !important;
}

.period-facets-container .facet {
	background: transparent;
	border: none;
	color: #0e4559;
	cursor: pointer;
	font-family: Roboto;
	font-weight: 100;
	margin-bottom: 0.7em;
	outline: none;
	width: 90%;
	text-align: left;
	text-decoration: underline;
}

.period-facets-container .facet.selected,
.period-facets-container .facet:hover {
	color: #0099CC;
}

.popup-property-history-inner-container .timeseries-list {
	flex: 1;
	padding-top: 1em;
}

input {
	height: 2.25em;
	line-height: 2.25em;
	border: 1px solid #9B9B9B;
	border-radius: 0;
	box-shadow: inset 2px 2px 4px 0 rgba(0,0,0,0.3);
	font-family: Roboto, sans-serif;
	font-size: 1.14em;
	outline: none;
	padding: 0 8px;
}

textarea {
	line-height: 2.25em;
	border: 1px solid #9B9B9B;
	border-radius: 0;
	box-shadow: inset 2px 2px 4px 0 rgba(0,0,0,0.3);
	font-family: Roboto, sans-serif;
	font-size: 1.14em;
	outline: none;
	padding: 0 8px;
}

/* V2 Generic Widget Styling */
.widget-subtitle {
	font-size: 14px;
	margin-top: 3px;
	/* Text shadow needed due to overlapping over the background image */
	text-shadow: 1px 1px 2px #fff;
}
.white-text .widget-subtitle {
	text-shadow: 1px 1px 2px #000;
}
.widget-subtitle.default {
	font-size: 1em;
	padding: 0.5em 1em 0.5em;
	margin: 0;
}

.widget-background-icon {
	position: absolute;
	right: 1.25em;
	bottom: 1.25em;
	height: 60px;
	width: 65px;
	overflow: hidden;
	z-index: 0;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	opacity: 0.4;
}

.widget-content {
	position: relative;
	z-index: 1;
}

.subtitle-blanket {
	background: rgba( 255, 255, 255, 0.5 );
}

.white-text .subtitle-blanket {
	background: rgba( 0, 0, 0, 0.2 );
}


/* CurrentValue Widget */
.current-value-widget {
	height: calc( 100% - 3.5em );
	position: relative;
	z-index: 1;
}

.current-value-text.long-text {
	font-size: 1.5em;
	line-height: initial;
	max-width: 100%;
	display: inline-block;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.current-value-text-container {
	padding-left: 15px;
}

.current-value-text {
	font-size: 3em;
	line-height: .9em;
}

.current-value-text-container a {
	color: inherit;
	text-decoration: none;
}

.current-value-widget .loading .property-unit {
	display: none;
}


/* Date-time pickers */
.widget-settings-selection {
	padding: 0.5em 1em 0.5em;
}

.widget-settings-selection.loading {
	position: relative;
}
.widget-settings-selection.loading div {
	opacity: 0.65;
}
.widget-settings-selection.loading:after {
	position: absolute;

	left: 50%;
	top: 50%;
	margin-left: -10px;
	margin-top: -10px;
}

/*.comparison-widget g.tick line,
.chart-canvas-container g.tick line {
	stroke: #1ba001;
}*/

/* DeviceManagement */
.device-management-widget {
	height: calc( 100% - 3.5em );
	display: flex;
	flex-direction: column;
}
.device-management-widget .widget-content {
	flex: 1 1 0;
	overflow-y: auto;
}

.device-management-widget .action-title {
	margin-bottom: 0.3em;
	font-weight: normal;
	font-size: 1em;
	line-height: 1.2em;
	text-transform: uppercase;
}

.device-management-widget .action-description {
	font-size: 0.88em;
	line-height: 1em;
	/*color: #848484;*/
}

.device-management-widget .invoke-action:after {
	margin-bottom: -0.3em;
}

.device-management-widget .invoke-action.success:after {
	content: '';
	background: url( ./../img/checked.svg );
	width: 20px;
	height: 20px;
	display: inline-block;
}

.device-management-widget .invoke-action-button {
	margin-top: 0.5em;
	margin-right: 0.3em;
}

.device-management-widget .management-action {
	padding-bottom: 2.5em;
	border-bottom: 1px solid #b3b3b3;
	margin-bottom: 1.5em;
}
.device-management-widget .management-action:last-child {
	border-bottom: none;
}

.device-management-widget .action-fields-list {
	padding: 1em 0 0;
}

.device-management-widget .management-action-field {
	margin-bottom: 1.1em;
}

.device-management-widget .management-action-field input {
	display: block;
	margin-top: 0.75em;
	width: 100%;
}


.device-management-widget .management-action-field label {
	font-size: 0.88em;
	line-height: 1em;
}

.device-management-widget .management-action-field .flatpickr-input {
	padding-top: 0;
	padding-bottom: 0;
	/*width: 50%;*/
	width: 100%;
	font-size: 1em;
}

.device-management-widget .management-action-field .vso-datespicker-container > div:after {
	position: absolute;
	top: 1.5rem;
}

.device-management-widget .management-action-field .vso-datespicker-container .close-icon:after {
	position: absolute;
	/*right: 52%;*/
	/*right: 0;*/
	margin-top: -2px;
}

.device-management-widget .management-action-field .field-error {
	font-size: 0.9em;
	color: #D0021B;
}

.drag-handl, .delete-widget,
.drag-handl, .edit-widget {
	display: none;
}
.widgets-dnd-enabled .drag-handle {
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 1000;
	display: block;
}

.widgets-dnd-enabled .widget-wrapper:hover .delete-widget,
.widgets-dnd-enabled .widget-wrapper:hover .edit-widget {
	height: 2rem;
	width: 2rem;
	background: #c00;
	/*border: 1px solid #c00;*/
	padding: 0.25rem 0.5rem;
	position: absolute;
	z-index: 999999;
	display: block;
	top: 0.5em;
	right: 0.5em;
}

.widgets-dnd-enabled .widget-wrapper:hover .edit-widget {
	right: 3em;
	background-color: #0099cc;
}

.widgets-dnd-enabled .delete-widget img,
.widgets-dnd-enabled .edit-widget img {
	height: 100%;
	width: 100%;
}

.widgets-dnd-enabled .widget-wrapper .react-resizable-handle {
	display: block;
	z-index: 999999;
}

.sample-widget-text-container {
	padding: 0 15px;
	position: relative;
	z-index: 1;
}

 @media ( max-width: 768px ) {
 	.logo-container {
 		background-size: cover;
 		width: 3em;
 	}
 	.widget-popup {
 		width: 96vw;
 		left: 2vw;
 		height: 89vh;
 		top: 10vh;
 	}
	.popup-markup-container {
		flex-direction: column;
	}
	.sow-widget-popup-search-sidebar {
		width: 100%;
		height: 6em;
	}
	.sow-filter-input {
		padding: 0 1em;
		height: auto;
	}
	.sow-filter-input input {
		border-bottom: none;
	}
	.sow-sensors-list {
		height: calc( 100% - 36px );
	}
	.sow-sensors-list ul {
		padding-top: 0;
	}
	.sow-sensors-list li {
		padding: 0.5em 20px;
	}
	.sow-sensors-popup-main-content.with-sidebar {
		width: 100%;
	}
	.sow-popup-content-left-column, .sow-popup-content-right-column {
		width: 98%;
		height: 50%;
	}
	.sow-popup-content-right-column {
		height: 80%;
	}
	.sow-sensors-popup-main-content {
		overflow: visible;
	}
	.sow-popup-overview-header {
		height: auto;
		padding-bottom: 0.5em;
	}
	.sow-popup-latest-messages-container h3 {
		margin: 0.5em 0;
	}
	.sow-popup-latest-messages-container li {
		padding: 0.5em
	}
	.close-widget-popup {
		top: 7.5em;
	}
}

/*timeline widget*/
.timeline-widget {
	height: calc( 100% - 3.5em );
}
.timelines-container {
	height: calc( 100% - 160px );
	padding: 1rem;
	overflow-y: auto;
}
.timeline-history {
	position: relative;
	width: 100%;
	height: 30px;
	margin: 2rem 0 4.5rem;
}
.timeline-history-title {
	margin-bottom: 3px;
	font-size: 0.8rem;
	font-weight: 300;
}
.timeline-element {
	height: 100%;
	float: left;
	cursor: pointer;
}
.timeline-tooltip {
	display: none;
	position: absolute;
	height: 40px;
	margin-top: -40px;
	padding: 0.2rem;
	font-size: 0.6rem;
}
.timeline-element:hover .timeline-tooltip {
	display: block;
}

.timeline-history-axis {
	font-size: 0.6rem;
}
.timeline-history-axis-date-container {
	float: left;
	height: 4px;
	border-top: 1px solid #000;
	border-left: 1px solid #000;
}
.timeline-history-axis-date-container:last-of-type {
	border: none;
	float: right;
}
.timeline-history-axis-date-container:nth-last-of-type(2) {
	border-right: 1px solid #000;;
}
.timeline-history-axis-date {
	max-width: 3rem;
	margin-top: 8px;
	margin-left: -10px;
}
.timeline-history-axis-date-container:last-of-type .timeline-history-axis-date {
	float: right;
	margin-top: 5px;
	margin-right: -0.5rem;
	text-align: right;
}

/* GAUGE WIDGET */
.gauge-widget {
	position: relative;
	z-index: 1;
	height: calc( 100% - 3.5em );
	overflow: hidden;
}

.gauge-chart-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 1.5rem;
	height: 100%;
}

.gauge-chart {
	position: relative;
	overflow: hidden;
	text-align: center;
}

.main-semi-circle {
	z-index: 1;
	position: absolute;
	top: 0%;
	border-radius: 50% 50% 50% 50%/100% 100% 0% 0%;
}

.small-semi-circle {
	z-index: 3;
	position: absolute;
	background-color: #222;
	margin-right: auto;
	border-radius: 50% 50% 50% 50%/100% 100% 0% 0%;
}

.hidden-semi-circle {
	z-index: 2;
	position: absolute;
	margin-left: auto;
	margin-right: auto;
	border-radius: 50% 50% 50% 50%/0% 0% 100% 100%;
	transform-origin: center top;
	transition: all 1.3s ease-in-out;
}

.gauge-data {
	z-index: 4;
	color: #fff;
	font-size: 1.5em;
	position: absolute;
	top: 45px;
	margin-left: auto;
	margin-right: auto;
	transition: all 1s ease-out;

	display: flex;
	justify-content: center;
	align-items: flex-end;
}

.gauge-data > p {
	margin: 5% 0;
}

.gauge-data.out-of-range,
.gauge-data.no-data {
	background: rgba( 0, 0, 0, .2 );
	font-size: 1.2em;
	padding: 3px;
	text-align: center;
	top: 0;
	transform: translate( 0, 50% );
}

.gauge-data.out-of-range > p,
.gauge-data.no-data > p {
	font-size: 1em;
}

.gauge-range {
	font-size: 1em;
	display: flex;
	justify-content: space-between;
}

.gauge-range > p {
	margin: 5px 0;
	font-family: Roboto;
	font-weight: 300;
}
.gauge-range .min-value {
	margin-left: 5%;
}
.gauge-range .max-value {
	margin-right: 5%;
}

/*csv upload*/
.upload-widget {
	height: 100%;
	margin-top: -3.3em;
	cursor: pointer;
	z-index: 100;
}
.upload-csv-input {
	width: 0;
	opacity: 0;
}
.upload-csv-button-text {
	position: absolute;
	width: 100%;
	height: 100%;
	padding: 0.7rem;
	text-align: center;
}
.widget-subtitle.csv-upload-message {
	height: 1.5rem;
	margin: 3.2rem 0 0.5rem;
	z-index: 10;
}

/* values list */
.values-widget {
	overflow-x: auto;
	/*display: flex;*/
}

.values-widget-text-container {
	padding: 15px;
}

.values-list-table {
	width: 100%;
}

.values-list {
	display: flex;
	flex-direction: column;
}

.values-list-header,
.value-item {
	display: flex;
	align-items: center;
	margin-bottom: 1em;
	justify-content: space-between;
}

.values-list .header-cell {
	font-weight: bold;
}

.values-list-header .header-cell,
.values-list .table-cell {
	padding: 0 0.375em;
}

.values-list .table-cell.time {
	cursor: pointer;
}