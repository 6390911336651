.hexbin-hexagon {
	stroke: #000;
	stroke-width: 1px;
}

.hexbin-container:hover .hexbin-hexagon {
	transition: 200ms;
	stroke: orange;
	stroke-width: 1px;
	stroke-opacity: 1;
}
.hexbin-tooltip {
	padding: 8px;
	border-radius: 4px;
	border: 1px solid black;
	background-color: white;

	/* HACK: ; */
	/* If not fixed the tooltip will move with scroll. */
	/* Can be removed after the lib devs accept the github PR: https://github.com/Asymmetrik/leaflet-d3/pull/46. */
	position: fixed !important;
}

