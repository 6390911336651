.vso-datespicker-container {
	display: flex;
	align-items: center;
	margin-bottom: 0.4em;
}

.vso-datespicker-container > div {
	width: 100%;
	position: relative;
}

.vso-rangepickers-container > div:first-of-type {
	margin-right: 0.5em;
}

.vso-rangepickers-container > div input {
	width: 100%;
}

.vso-datespicker-container > div:after {
	content: '';
	display: block;

	position: absolute;
	top: 0.8em;
	left: 0.75em;
	cursor: pointer;

	background: url( ../../img/calendar.svg ) no-repeat;
	background-size: contain;

	width: 18px;
	height: 18px;

	pointer-events: none;
}

.vso-datespicker-container input {
	display: block;
	width: 100%;
	padding: 0.75em 1.8em 0.75em 2.5em;
	border: none;
	border-radius: 3px;
	box-shadow: 0 2px 4px 0 rgba(0,0,0,0.3);
	font-family: TitilliumWeb, sans-serif;
	font-size: 0.88em;
	font-weight: 400;
	height: 3em;
	line-height: 3em;
	outline: none;
	color: #000;
	width: 70%;
}

.vso-datespicker-container .close-icon {
	display: block;
	text-decoration: none;

	background-color: transparent;
	border: 0;
	cursor: pointer;
	height: 0;
	outline: 0;
	padding: 0;
	vertical-align: middle;
}

.vso-datespicker-container .close-icon:after {
	background: #fff;
	border-radius: 50%;
	bottom: 0;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	color: #000;
	content: "\D7";
	cursor: pointer;
	font-size: 18px;
	height: 20px;
	width: 25px;
	line-height: 1;
	margin: -8px auto 0;
	position: absolute;
	right: 8px;
	text-align: center;
	top: 50%;
}

/* Wilfred's design */
.flatpickr-month,
.flatpickr-weekdays,
span.flatpickr-weekday {
	background: #fff;
}
span.flatpickr-weekday {
	font-weight: normal;
}

.flatpickr-prev-month svg, 
.flatpickr-next-month svg {
	stroke: #000;
}

.flatpickr-current-month {
	font-size: 1.15em;
	line-height: 1.2em;
}

.flatpickr-current-month span.cur-month:hover,
.flatpickr-current-month .numInputWrapper:hover,
.flatpickr-time.time24hr .numInputWrapper:hover {
	background: #e5f4f9;
}

.flatpickr-current-month span.cur-month,
.flatpickr-current-month input.cur-year {
	box-shadow: none;
	color: #000;
	font-weight: normal;
}

span.flatpickr-weekday {
	color: #848484;
	font-size: 1em;
	line-height: 1.15em;
}

.flatpickr-day {
	color: #000;
	font-family: TitilliumWeb, sans-serif;
	font-weight: bold;
}
.flatpickr-day:hover {
	background: #e5f4f9;
	border-color: #e5f4f9;
}
.flatpickr-day.today:hover {
	background: #e5f4f9;
	color: #484848;
}

.flatpickr-prev-month:hover svg, 
.flatpickr-next-month:hover svg {
	fill: #0099CC;
}


.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, 
.flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, 
.flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, 
.flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, 
.flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, 
.flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
	background: #0099CC;
	border-color: #0099CC;
}

.flatpickr-day {
	border-radius: 0;
}

.flatpickr-day.prevMonthDay {
	color: #9B9B9B;
}

.flatpickr-time input {
	font-family: TitilliumWeb, sans-serif;
	color: #000;
}

.flatpickr-time input.flatpickr-minute, 
.flatpickr-time input.flatpickr-second {
	font-weight: 600;
}